import styled from 'styled-components';
import akidoLogo from '../../../assets/images/akido_logo.png';
import { UserMenu } from '../UserMenu';

const TopHeader = styled.div`
  padding: 15px 0px 15px 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f6f8fa;
`;

export function PageHeaderV2(props: { shouldAutoSaveOnLogout: boolean }) {
  return (
    <TopHeader>
      <div>
        <img src={akidoLogo} alt='Akido logo' />
      </div>
      <UserMenu
        shouldAutoSaveOnLogout={props.shouldAutoSaveOnLogout}
        styles={{ container: { marginRight: '25px' } }}
      />
    </TopHeader>
  );
}
